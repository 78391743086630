import React, { useEffect, useState } from "react";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {BooleanField, CreateButton, Datagrid, FunctionField, List, TextField, TopToolbar} from "react-admin";
import {PinkPagination} from "../../components/PinkPagination";
import {EmptyPage} from "../../components/EmtpyPage";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";

const ExtrasList = ({permissions}) => {
    const [selectedPosSetId, setSelectedPosSetId] = useState(localStorage.getItem("extraPosSetId") || 0)
    const hasEdit = getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("extraPosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    return (
        <div>
            <SelectCompanyWithBusinessUnitInput companySelectDisabled={true} getResource={'extra'} setPosSet={setSelectedPosSetId}/>
            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List actions={<TopToolbar>
                    <CreateButton label={"extras.new"}/>
                </TopToolbar>}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      filter={{businessUnitId: selectedPosSetId}}
                      title={"extras.title"} empty={<EmptyPage text={"company.empty"} createEnabled={hasEdit}/>}>
                    <Datagrid rowClick={hasEdit} bulkActionButtons={false}>
                        <TextField source="id" label={"extras.id"}/>
                        <TextField source="name" label={"extras.name"}/>
                    </Datagrid>
                </List>
            }
        </div>
    );
}

export default ExtrasList;