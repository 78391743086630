import {useListContext} from "react-admin";
import {useEffect} from "react";

const BulkActionButtonsToPrinter = ({setSelectedIds}) => {
    const listContext = useListContext();

    useEffect(() => {
        const selected = listContext.data.filter(it => it.selected).map(it => it.id);
        setSelectedIds(selected);
        listContext.onSelect(selected);
    }, [listContext.data]);

    useEffect(() => {
        setSelectedIds(listContext.selectedIds);
    }, [listContext.selectedIds]);

    return null;
}

export default BulkActionButtonsToPrinter;