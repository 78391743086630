import React, { useEffect, useState, useCallback } from 'react';
import { Datagrid, InfiniteList, TextInput, useTranslate, TextField } from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dataProvider from "../../components/dataProvider";
import MuiTextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { uuidv4 } from "../../tools/generate";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BulkActionButtonsToExtra from "./BulkActionButtonsToExtra";

const ExtrasDetails = (props) => {
    const [warehouseProducts, setWarehouseProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [units, setUnits] = useState([]);
    const { setValue, getValues } = useFormContext();
    const translate = useTranslate();
    const NoProducts = () => <Typography>{translate("products.empty")}</Typography>;

    useEffect(() => {
        dataProvider.get('extra/components', { posSetId: localStorage.getItem("extraPosSetId") })
            .then(value => {
                setWarehouseProducts(value.data);
            })
            .catch(reason => {
                console.log(reason);
            });
    }, []);

    useEffect(() => {
        const updatedUnits = [...units];
        if (updatedUnits[0]) {
            updatedUnits[0].productUnitIdsForExtra = selectedIds;
        }
        setUnits(updatedUnits);
    }, [selectedIds]);

    useEffect(() => {
        const data = getValues();
        if (data.id) {
            dataProvider.getOne("extra", { id: data.id })
                .then(value => {
                        const updatedUnits = value.data.units.map(unit => {
                            return {
                                ...unit,
                                unitComponents: unit.unitComponents.map(component => {
                                    const selectedProduct = warehouseProducts.find(product => product.id === component.warehouseProductId);
                                    return {
                                        ...component,
                                        unitSelect: selectedProduct ? selectedProduct.units : []
                                    };
                                })
                            };
                        });
                        setUnits(updatedUnits);
                        setSelectedProducts(value.data.units[0]?.productUnitIdsForExtra);
                        setSelectedIds(value.data.units[0]?.productUnitIdsForExtra);
                })
                .catch(reason => {
                    console.log(reason);
                });
        } else {
            setUnits([{
                unit: 6,
                quantity: 1,
                defaultPrice: null,
                unitComponents: [{
                    FEid: uuidv4(),
                    warehouseProductId: null,
                    warehouseProductUnitId: null,
                    quantity: null,
                    unitSelect: [],
                }]
            }]);
        }
    }, [warehouseProducts]);

    useEffect(() => {
        setValue('units', units);
    }, [units]);

    const handleProductChange = useCallback((componentIndex, newValue) => {
        const updatedUnits = [...units];
        updatedUnits[0].unitComponents[componentIndex].warehouseProductId = newValue?.id || null;
        updatedUnits[0].unitComponents[componentIndex].unitSelect = newValue?.units || [];
        setUnits(updatedUnits);
    }, [units]);

    const handleAddProduct = useCallback(() => {
        const updatedUnits = [...units];
        updatedUnits[0].unitComponents.push({
            FEid: uuidv4(),
            warehouseProductId: null,
            warehouseProductUnitId: null,
            quantity: null,
        });
        setUnits(updatedUnits);
    }, [units]);

    const handleRemoveProduct = useCallback((componentIndex) => {
        const updatedUnits = [...units];
        updatedUnits[0].unitComponents.splice(componentIndex, 1);
        setUnits(updatedUnits);
    }, [units]);

    const handleDefaultPriceChange = useCallback((event) => {
        const value = event.target.value;
        if (/^[0-9]*$/.test(value)) {
            const updatedUnits = [...units];
            updatedUnits[0].defaultPrice = value;
            setUnits(updatedUnits);
        }
    }, [units]);

    const handleQuantityChange = useCallback((componentIndex, event) => {
        const value = event.target.value;
        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            const updatedUnits = [...units];
            updatedUnits[0].unitComponents[componentIndex].quantity = value;
            setUnits(updatedUnits);
        }
    }, [units]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <TextInput name={'name'} source={'name'} required label={"extras.name"} sx={{ width: '300px' }} />
            <MuiTextField
                label={translate("extras.defaultPrice")}
                required
                variant={'filled'}
                sx={{ width: '300px', marginTop: '-10px', marginBottom: '10px' }}
                value={units[0]?.defaultPrice || ''}
                onChange={handleDefaultPriceChange}
            />
            <Box sx={{ maxHeight: '600px', overflow: 'auto', marginTop: '10px', marginBottom: '20px' }}>
                <InfiniteList empty={<NoProducts />} exporter={false} resource={"extra/products/byUnitPageable"}
                              title={"módosítás"} pagination={false} perPage={2000}
                              filter={{ buId: localStorage.getItem("extraPosSetId"), size: 2000 }}
                >
                    <Datagrid
                        bulkActionButtons={<BulkActionButtonsToExtra selectedProducts={selectedProducts} setSelectedIds={setSelectedIds} />}>
                        <TextField source="id" label={"extras.id"} />
                        <TextField source="name" label={"extras.name"} />
                    </Datagrid>
                </InfiniteList>
            </Box>

            <Button sx={{ position: 'absolute', bottom: 0, left: 415, width: '300px', textTransform: 'lowercase' }} onClick={handleAddProduct}>
                <AddCircleOutlineIcon fontSize={"small"} sx={{ color: "#ff71bc" }} />{translate("extras.addUnit")}
            </Button>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '370px' }}>
                {units[0]?.unitComponents?.map((component, componentIndex) => (
                    <Box key={component.FEid} sx={{ display: 'flex', flexDirection: 'row', width: '100%', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', marginBottom: '16px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Autocomplete
                                sx={{ width: '300px' }}
                                options={warehouseProducts || []}
                                value={warehouseProducts.find(product => product.id === component.warehouseProductId) || null}
                                onChange={(event, newValue) => handleProductChange(componentIndex, newValue)}
                                getOptionLabel={(option) => option?.name || ""}
                                renderInput={(params) => <MuiTextField {...params} variant={'filled'} label={translate("extras.warehouseProduct")} />}
                            />
                            <MuiTextField
                                label={translate("extras.quantity")}
                                variant={'filled'}
                                sx={{ width: '300px' }}
                                value={component.quantity || ''}
                                onChange={(event) => handleQuantityChange(componentIndex, event)}
                            />
                            <Autocomplete
                                renderInput={(params) => <MuiTextField {...params} variant={'filled'} label={translate("extras.unit")} />}
                                options={component.unitSelect || []}
                                getOptionLabel={(option) => option?.unitName || ""}
                                value={component.unitSelect?.find(unit => unit.id === component.warehouseProductUnitId) || null}
                                onChange={(event, newValue) => {
                                    const updatedUnits = [...units];
                                    updatedUnits[0].unitComponents[componentIndex].warehouseProductUnitId = newValue?.id || null;
                                    setUnits(updatedUnits);
                                }}
                            />
                        </Box>
                        <Button onClick={() => handleRemoveProduct(componentIndex)}>
                            <RemoveCircleOutlineIcon />
                        </Button>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default ExtrasDetails;