import './App.css';
import * as React from "react";
import {Admin, CustomRoutes, Resource} from "react-admin";
import dataProvider from "./components/dataProvider";
import i18nProvider from "./locales/locale";
import {PinkLayout} from "./components/PinkLayout";
import authProvider from "./components/cognito/authProvider";
import pposLogin from "./components/cognito/PposLogin";
import {GroupCreate} from "./pages/groups/GroupCreate";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {getValidPrivilegesIds} from "./components/authentication/PermissionHandler";
import {BusinessUnitList} from "./pages/businessUnit/BusinessUnitList";
import {BusinessUnitCreate} from "./pages/businessUnit/BusinessUnitCreate";
import {BusinessUnitEdit} from "./pages/businessUnit/BusinessUnitEdit";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {DeviceList} from "./pages/device/DeviceList";
import {DeviceEdit} from "./pages/device/DeviceEdit";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ProductList from "./pages/products/ProductList";
import ProductEdit from "./pages/products/ProductEdit";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ProductCreate from "./pages/products/ProductCreate";
import UserList from "./pages/user/UserList";
import UserInviteCreate from "./pages/user/UserInviteCreate";
import {TechnicalUserCreate} from "./pages/user/TechnicalUserCreate";
import {UserEdit} from "./pages/user/UserEdit";
import PersonIcon from "@mui/icons-material/Person";
import PriceListTemplateList from "./pages/priceListTemplate/PriceListTemplateList";
import {PriceListTemplateShow} from "./pages/priceListTemplate/PriceListTemplateShow";
import {PriceListTemplateEdit} from "./pages/priceListTemplate/PriceListTemplateEdit";
import {PriceListTemplateCreate} from "./pages/priceListTemplate/PriceListTemplateCreate";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {ReportCreate} from "./pages/report/CreateReport";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {QuickMenuCreate} from "./pages/quickMenu/QuickMenuCreate";
import MenuIcon from '@mui/icons-material/Menu';
import {SendPriceListToPosSet} from "./pages/priceListToPosSets/SendPriceListToPosSet";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {theme} from "./config/theme";
import {ListPriceListsOfPos} from "./pages/priceListToPosSets/ListPriceListsOfPos";
import AppsIcon from '@mui/icons-material/Apps';
import {CompanyList} from "./pages/company/CompanyList";
import BusinessIcon from '@mui/icons-material/Business';
import {CompanyEdit} from "./pages/company/CompanyEdit";
import {CompanyCreate} from "./pages/company/CompanyCreate";
import {Dashboard} from "./pages/dashboard/Dashboard";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Route} from "react-router-dom";
import {ProfileEdit} from "./pages/profile/Profile";
import SettingsIcon from '@mui/icons-material/Settings'
import {SendConfigToPosSet} from "./pages/config/SendConfigToPosSet";
import {RMSErrorMessagesList} from "./pages/RMSErrorMessages/RMSErrorMessagesList";
import FeedbackIcon from '@mui/icons-material/Feedback';
import {EmptyPage} from "./components/EmtpyPage";
import AddCardIcon from '@mui/icons-material/AddCard';
import WarehouseProductList from "./pages/warehouse/warehouseProducts/WarehouseProductList";
import {Warehouse} from "@mui/icons-material";
import WarehouseProductCreate from "./pages/warehouse/warehouseProducts/WarehouseProductCreate";
import WarehouseProductEdit from "./pages/warehouse/warehouseProducts/WarehouseProductEdit";
import WarehouseStorageList from "./pages/warehouse/warehouseStorages/WarehouseStorageList";
import WarehouseStorageCreate from "./pages/warehouse/warehouseStorages/WarehouseStorageCreate";
import WarehouseStorageEdit from "./pages/warehouse/warehouseStorages/WarehouseStorageEdit";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SuppliersCreate from "./pages/warehouse/suppliers/SuppliersCreate";
import SuppliersEdit from "./pages/warehouse/suppliers/SuppliersEdit";
import SuppliersList from "./pages/warehouse/suppliers/SuppliersList";
import WarehouseOperationsList from "./pages/warehouse/warehouseOperations/WarehouseOperationsList";
import WarehouseOperationsCreate from "./pages/warehouse/warehouseOperations/WarehouseOperationsCreate";
import BuildIcon from '@mui/icons-material/Build';
import WarehouseOperationsEdit from "./pages/warehouse/warehouseOperations/WarehouseOperationsEdit";
import InventoryList from "./pages/warehouse/inventory/InventoryList";
import InventoryCreate from "./pages/warehouse/inventory/InventoryCreate";
import InventoryShow from "./pages/warehouse/inventory/InventoryShow";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WarehouseStockList from "./pages/warehouse/warehouseStock/WarehouseStockList";
import CreateStockChangeReport from "./pages/warehouse/stockChangeReport/CreateStockChangeReport";
import Purchase from "./pages/corporateAdmin/Sales/Purchase/Purchase";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PurchaseHistory from "./pages/corporateAdmin/Sales/Purchase/PurchaseHistory";
import SimpleSteps from "./pages/corporateAdmin/Sales/Purchase/SimpleSteps";
import ShiftClosureList from "./pages/corporateAdmin/shiftClosure/ShiftClosureList";
import AlarmIcon from '@mui/icons-material/Alarm';
import SimpleBack from "./pages/corporateAdmin/Sales/Purchase/SimpleBack";
import FinanceList from "./pages/finance/FinanceList";
import PaidIcon from '@mui/icons-material/Paid';
import {useEffect} from "react";
import SourceIcon from '@mui/icons-material/Source';
import NtakIntegration from "./pages/ntakIntegration/NtakIntegration";
import { VoucherGeneratorList } from "./pages/corporateAdmin/voucher/VoucherGeneratorList"
import { VoucherGeneratorCreate } from './pages/corporateAdmin/voucher/VoucherGeneratorCreate';
import VoucherGeneratorShow from "./pages/corporateAdmin/voucher/VoucherGeneratorShow";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TransactionUpload from "./pages/corporateAdmin/transactionUpload/TransactionUpload";
import PrinterList from "./pages/Printer/PrinterList";
import PrinterCreate from "./pages/Printer/PrinterCreate";
import PrinterEdit from "./pages/Printer/PrinterEdit";
import PrintIcon from '@mui/icons-material/Print';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QuickRIntegration from "./pages/quickR/QuickRIntegration";
import ExtrasList from "./pages/extras/ExtrasList";
import ExtrasCreate from "./pages/extras/ExtrasCreate";
import ExtrasEdit from "./pages/extras/ExtrasEdit";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Shop from "./pages/shop/Shop"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const App = () => {
    const accessDenied = <EmptyPage empty={false}/>
useEffect(() => {
    dataProvider.get('businessUnit/simplified')
        .then(response => {
            localStorage.setItem('allSimplified', response.data)
        })
}, [])
    return (

        <Admin dataProvider={dataProvider}
               authProvider={authProvider}
               loginPage={pposLogin}
               layout={PinkLayout}
               theme={theme}
               i18nProvider={i18nProvider}
               disableTelemetry={true}
               requireAuth={true}
        >
            <CustomRoutes>
                <Route path="/profile" element={<ProfileEdit/>}/>
            </CustomRoutes>
            {permissions =>
                <>
                    {getValidPrivilegesIds("DASHBOARD", "GET").some(r => permissions.includes(r)) ?
                        <Resource name="dashboard" options={{label: 'dashboard.title'}}
                                  list={<Dashboard permissions={permissions}/>}
                                  icon={DashboardIcon}/> :
                        <Resource name="company" options={{label: 'company.title'}}
                                  list={<CompanyList permissions={permissions}/>}
                                  create={CompanyCreate}
                                  edit={CompanyEdit}
                                  icon={BusinessIcon}/>
                    }
                    <Resource name="company" options={{label: 'company.title'}}
                              list={CompanyList}
                              create={CompanyCreate}
                              edit={getValidPrivilegesIds("COMPANY", "EDIT").some(r => permissions.includes(r)) ?
                                  CompanyEdit : accessDenied}
                              icon={BusinessIcon}/>

                    <Resource name="businessUnit" options={{label: 'businessUnits.title'}}
                              list={BusinessUnitList}
                              create={BusinessUnitCreate}
                              edit={getValidPrivilegesIds("BUSINESS_UNIT", "EDIT").some(r => permissions.includes(r)) ?
                                  BusinessUnitEdit : accessDenied}
                              icon={StorefrontIcon}/>

                    <Resource name="ntakIntegration" options={{label: 'businessUnits.integration'}}
                              list={getValidPrivilegesIds("BUSINESS_UNIT", "EDIT").some(r => permissions.includes(r)) ?
                                  NtakIntegration : accessDenied}
                              icon={SourceIcon}/>

                    <Resource name="product" options={{label: 'products.title'}}
                              list={getValidPrivilegesIds("PRODUCT", "LIST").some(r => permissions.includes(r)) ?
                                  <ProductList permissions={permissions}/> : accessDenied}
                              edit={getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ?
                                  <ProductEdit permissions={permissions}/> : accessDenied}
                              create={getValidPrivilegesIds("PRODUCT", "CREATE").some(r => permissions.includes(r)) ?
                                  <ProductCreate permissions={permissions}/> : accessDenied}
                              icon={FastfoodIcon}/>

                    <Resource name="priceList" options={{label: 'priceListTemplate.title'}}
                              list={getValidPrivilegesIds("PRICELIST", "LIST").some(r => permissions.includes(r)) ?
                                  PriceListTemplateList : accessDenied}
                              show={getValidPrivilegesIds("PRICELIST", "LIST").some(r => permissions.includes(r)) ?
                                  PriceListTemplateShow : accessDenied}
                              edit={getValidPrivilegesIds("PRICELIST", "EDIT").some(r => permissions.includes(r)) ?
                                  PriceListTemplateEdit : accessDenied}
                              create={getValidPrivilegesIds("PRICELIST", "CREATE").some(r => permissions.includes(r)) ?
                                  PriceListTemplateCreate : accessDenied}
                              icon={MenuBookIcon}/>

                    <Resource name="quickMenu" options={{label: 'quickMenu.title'}}
                              list={getValidPrivilegesIds("PRICELIST", "CREATE").some(r => permissions.includes(r)) ?
                                  QuickMenuCreate : accessDenied}
                              icon={AppsIcon}/>

                    <Resource name="priceListToPosSet" options={{label: 'priceListToPosSets.title'}}
                              list={getValidPrivilegesIds("PRICELIST_TO_POSSET", "LIST").some(r => permissions.includes(r)) ?
                                  SendPriceListToPosSet : accessDenied}
                              icon={CloudDownloadIcon}/>

                    <Resource name="pos/pricelist" options={{label: 'priceListsOfPos.title'}}
                              list={getValidPrivilegesIds("POS_WITH_PRICELIST", "LIST").some(r => permissions.includes(r)) ?
                                  ListPriceListsOfPos : accessDenied}
                              icon={MenuIcon}/>

                    <Resource name="user" options={{label: 'technicalUsers.title'}}
                              list={getValidPrivilegesIds("USER", "LIST").some(r => permissions.includes(r)) ?
                                  UserList : accessDenied}
                              create={getValidPrivilegesIds("USER", "CREATE").some(r => permissions.includes(r)) ?
                                  TechnicalUserCreate : accessDenied}
                              edit={getValidPrivilegesIds("USER", "EDIT").some(r => permissions.includes(r)) ?
                                  UserEdit : accessDenied}
                              icon={PersonIcon}>
                        <Route path="/userInvite"
                               element={getValidPrivilegesIds("USERINVITE", "INVITE").some(r => permissions.includes(r)) ?
                                   <UserInviteCreate/> : accessDenied}/>
                    </Resource>

                    <Resource name="group" options={{label: 'groups.title'}}
                              list={getValidPrivilegesIds("GROUP", "LIST").some(r => permissions.includes(r)) ?
                                  GroupCreate : accessDenied}
                              icon={AccountTreeIcon}/>

                    <Resource name="configToPosSet" options={{label: 'config.title'}}
                              list={getValidPrivilegesIds("CONFIG", "LIST").some(r => permissions.includes(r)) ?
                                  SendConfigToPosSet : accessDenied}
                              icon={SettingsIcon}/>

                    <Resource name="device" options={{label: 'device.title'}}
                              list={getValidPrivilegesIds("DEVICE", "LIST").some(r => permissions.includes(r)) ?
                                  DeviceList : accessDenied}
                              edit={getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ?
                                  DeviceEdit : accessDenied}
                              icon={PointOfSaleIcon}/>

                    <Resource name="rMSErrorMessages" options={{label: 'rMSErrorMessagesList.title'}}
                              list={getValidPrivilegesIds("RMS_ERROR_MESSAGES", "LIST").some(r => permissions.includes(r)) ?
                                  RMSErrorMessagesList : accessDenied}
                              icon={FeedbackIcon}/>

                    <Resource name="report" options={{label: 'report.title'}}
                              list={getValidPrivilegesIds("REPORT", "CREATE").some(r => permissions.includes(r)) ?
                                  ReportCreate : accessDenied}
                              icon={AssessmentIcon}/>

                    <Resource name="dashboard" options={{label: 'dashboard.title'}}
                              list={getValidPrivilegesIds("DASHBOARD", "LIST").some(r => permissions.includes(r)) ?
                                  Dashboard : accessDenied}
                              icon={DashboardIcon}/>

                    <Resource name="warehouse-product" options={{label: 'warehouse.title'}}
                              list={<WarehouseProductList permissions={permissions}/>}
                              create={getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r)) ?
                                  <WarehouseProductCreate permissions={permissions}/> : null}
                              edit={getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ?
                                  <WarehouseProductEdit permissions={permissions}/> : null}
                              icon={FastfoodIcon}/>

                    <Resource name="warehouse" options={{label: 'storage.title'}}
                              list={<WarehouseStorageList permissions={permissions}/>}
                              create={getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r)) ?
                                  <WarehouseStorageCreate permissions={permissions}/> : null}
                              edit={getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ?
                                  <WarehouseStorageEdit permissions={permissions}/> : null}
                              icon={Warehouse}/>

                    <Resource name="supplier" options={{label: 'suppliers.title'}}
                              list={<SuppliersList permissions={permissions}/>}
                              create={getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r)) ?
                                  <SuppliersCreate permissions={permissions}/> : null}
                              edit={getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ?
                                  <SuppliersEdit permissions={permissions}/> : null}
                              icon={LocalShippingIcon}/>

                    <Resource name="warehouse-operation" options={{label: 'operations.title'}}
                              list={<WarehouseOperationsList permissions={permissions}/>}
                              create={getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r)) ?
                                  <WarehouseOperationsCreate permissions={permissions}/> : null}
                              edit={getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ?
                                  <WarehouseOperationsEdit permissions={permissions}/> : null}
                              icon={BuildIcon}/>

                    <Resource name="inventory" options={{label: 'inventory.title'}}
                              list={<InventoryList permissions={permissions}/>}
                              create={getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r)) ?
                                  <InventoryCreate permissions={permissions}/> : null}
                              show={getValidPrivilegesIds("WAREHOUSE", "GET").some(r => permissions.includes(r)) ?
                                  <InventoryShow permissions={permissions}/> : null}
                              icon={ChecklistRtlIcon}/>

                    <Resource name="stockpile" options={{label: 'stockpile.title'}}
                              list={getValidPrivilegesIds("WAREHOUSE", "GET").some(r => permissions.includes(r)) ?
                                  <WarehouseStockList permissions={permissions}/> : accessDenied}
                              icon={ListAltIcon}/>

                    <Resource name="stock-change-report/report" options={{label: 'stockReport.title'}}
                              list={getValidPrivilegesIds("WAREHOUSE", "GET").some(r => permissions.includes(r)) ?
                                  <CreateStockChangeReport permissions={permissions}/> : accessDenied}
                              icon={AssessmentIcon}/>

                    <Resource name="license" options={{label: 'purchase.title'}}
                              list={<SimpleSteps Resource={"license"}/>}
                              icon={AddCardIcon}/>

                    <Resource name={"shop"} options={{label: 'shop.title'}}
                              list={<Shop/>}
                              icon={ShoppingCartIcon}
                    />

                    <Resource name="manageLicense" options={{label: 'purchase.title'}}
                              list={getValidPrivilegesIds("LICENSE", "GET", true).some(r => permissions.includes(r)) ?
                                  <Purchase Resource={"manageLicense"} permissions={permissions}/> : accessDenied}
                              icon={AddCardIcon}/>

                    <Resource name="purchase" options={{label: 'purchaseHistory.title'}}
                              list={getValidPrivilegesIds("PURCHASE", "LIST", true).some(r => permissions.includes(r)) ?
                                  <PurchaseHistory permissions={permissions}/> : accessDenied}
                              icon={CreditScoreIcon}/>


                    <Resource name="shift" options={{label: 'shiftClosure.title'}}
                              list={getValidPrivilegesIds("SHIFT", "LIST", true).some(r => permissions.includes(r)) ?
                                  <ShiftClosureList permissions={permissions}/> : accessDenied}
                              icon={AlarmIcon}/>

                    <Resource name="finance" options={{label: 'finance.title'}}
                              list={getValidPrivilegesIds("PURCHASE", "STORNO", true).some(r => permissions.includes(r)) ?
                                  <FinanceList permissions={permissions}/> : accessDenied}
                              icon={PaidIcon}/>

                    <Resource name="voucherGenerator" options={{label: 'voucher.title'}}
                              list={getValidPrivilegesIds("PURCHASE", "STORNO", true).some(r => permissions.includes(r)) ?
                                  <VoucherGeneratorList permissions={permissions}/> : accessDenied}
                              create={getValidPrivilegesIds("PURCHASE", "STORNO").some(r => permissions.includes(r)) ?
                                  <VoucherGeneratorCreate permissions={permissions}/> : null}
                              show={<VoucherGeneratorShow permissions={permissions}/>}
                              icon={CardGiftcardIcon}/>

                    <Resource name="transactionUpload" options={{label: 'transactionUpload.title'}}
                              list={getValidPrivilegesIds("PURCHASE", "STORNO", true).some(r => permissions.includes(r)) ?
                                  <TransactionUpload permissions={permissions}/> : accessDenied}
                              icon={CloudUploadIcon}/>

                    <Resource name="v1/order/printer" options={{label: 'printer.title'}}
                              list={getValidPrivilegesIds("ORDER_PRINTER", "LIST", true).some(r => permissions.includes(r)) ?
                                  <PrinterList permissions={permissions}/> : accessDenied}
                              create={getValidPrivilegesIds("ORDER_PRINTER", "CREATE").some(r => permissions.includes(r)) ?
                                  <PrinterCreate permissions={permissions}/> : null}
                              edit={getValidPrivilegesIds("ORDER_PRINTER", "EDIT").some(r => permissions.includes(r)) ?
                                  <PrinterEdit permissions={permissions}/> : null}
                              icon={PrintIcon}/>

                    <Resource name={"extra"} options={{label: 'extras.title'}}
                                list={getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ?
                                    <ExtrasList permissions={permissions}/> : accessDenied}
                                create={<ExtrasCreate/>}
                                edit={<ExtrasEdit/>}
                                icon={LibraryAddIcon}/>

                    <Resource name="back" options={{label: 'purchase.title'}}
                              list={<SimpleBack/>}/>

                    <Resource name="quickR" options={{label: 'quickR.title'}}
                              list={getValidPrivilegesIds("COMPANY", "EDIT").some(r => permissions.includes(r)) ?
                                  <QuickRIntegration permissions={permissions}/> : accessDenied}
                              icon={QrCodeScannerIcon}/>

                </>
            }
        </Admin>
    );
}

export default App;
