import React, { memo, useCallback } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider } from '@mui/material/styles';
import './ListItems.css';
import { useTranslate } from "react-admin";
import { Button, Checkbox, Tooltip } from "@mui/material";
import { muiDatagridTheme } from "./MuiDatagridTheme";
import Chip from "@mui/material/Chip";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RecyclingIcon from "@mui/icons-material/Recycling";

const EditPriceDataGrid = memo(({ rows }) => {
    const translate = useTranslate();
    const { setValue } = useFormContext();
    const apiRef = useGridApiRef();

    const columns = [
        {
            field: 'name',
            headerName: 'Név',
            valueGetter: (value, row) => row.name
        },
        {
            field: 'quantityUnit',
            headerName: 'Kiszerelés',
            valueGetter: (value, row) => `${row.quantity} ${row.unit}`
        },
        {
            field: 'vatName',
            headerName: 'ÁFA csoport',
            valueGetter: (value, row) => row.vatName
        },
        {
            field: "hasRecipe",
            headerName: translate("products.recipe"),
            renderCell: (params) => params.row.hasRecipe ? <Tooltip title={translate("products.recipeTooltip")}> <ReceiptLongIcon /> </Tooltip> : ""
        },
        {
            field: "depositFee",
            headerName: translate("products.refundBottle"),
            renderCell: (params) => params.row.depositFee.hasDepositFee === "MULTI_WAY" || params.row.depositFee === "ONE_WAY" ? <Tooltip title={translate("products.refundBottle")}> <RecyclingIcon /> </Tooltip> : ""
        },
        {
            field: 'isTakeawayAllowed',
            headerName: 'Elvihető',
            type: "boolean",
            valueGetter: (value, row) => row.isTakeawayAllowed
        },
        {
            field: 'takeawayVatName',
            headerName: 'Elviteles ÁFA',
            valueGetter: (value, row) => row.takeawayVatName
        },
        {
            field: 'bulk',
            headerName: translate("products.bulkItem"),
            type: "boolean",
            width: 110,
            valueGetter: (value, row) => row.bulk
        },
        {
            field: 'tags',
            headerName: 'Cimkék',
            width: 300,
            renderCell: (params) => (
                <div>
                    {params.row.tags?.map((value) => (
                        <Chip key={value.id} label={value.name} />
                    ))}
                </div>
            )
        },
        {
            field: 'price',
            headerName: 'Ár',
            type: "number",
            editable: true,
            renderHeader: (params) => (
                <Tooltip title="A szerkesztéshez kattintson duplán a változtatni kívánt árra">
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            ),
            renderCell: (params) => params.value == null ? '' : `${params.value} Ft`
        },
        {
            field: 'quickrGrossPrice',
            headerName: 'QuickR Ár',
            type: "number",
            editable: true,
            renderHeader: (params) => (
                <Tooltip title="A szerkesztéshez kattintson duplán a változtatni kívánt árra">
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            ),
            renderCell: (params) => params.value == null ? '' : `${params.value} Ft`
        },
        {
            field: 'quickrActive',
            headerName: 'Elérhető QuickR-ben',
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.quickrActive}
                    onChange={(event) => {
                        const newRows = rows.map(row => row.id === params.row.id ? { ...row, quickrActive: event.target.checked } : row);
                        setValue('products', newRows);
                    }}
                />
            )
        },
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    console.log(params);
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => row.productUnitId !== params.id);
                    setValue('products', newRows);
                };
                return <Button disabled={params.row.depositFeeType !== 'NONE'} onClick={onClick}><DeleteIcon /></Button>;
            }
        },
    ];

    const processRowUpdate = useCallback(
        async (newRow) => {
            const newRows = rows.map(r => (r.id === newRow.id) ? { ...r, price: newRow.price, quickrGrossPrice: newRow.quickrGrossPrice } : r);
            setValue('products', newRows);
        },
        [rows, setValue],
    );

    const onProcessRowUpdateError = (error) => {
        console.log(error);
    };

    return (
        <div style={{ height: 400 }}>
            <ThemeProvider theme={muiDatagridTheme}>
                <DataGrid
                    apiRef={apiRef}
                    getRowId={row => row.productUnitId ? row.productUnitId : row.id}
                    pageSizeOptions={[5, 10, 20, 100]}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                />
            </ThemeProvider>
        </div>
    );
});

export default EditPriceDataGrid;