import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { Button, useTranslate, useNotify } from "react-admin";
import SaveIcon from '@mui/icons-material/Save';
import dataProvider from "../../components/dataProvider";

const QuickRIntegration = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const [company, setCompany] = useState([]);
    const [business, setBusiness] = useState([]);
    const [pricelist, setPricelist] = useState([]);
    const [pricelistInstance, setPricelistInstance] = useState([]);
    const [instanceId, setInstanceId] = useState(null);
    const [integrationKey, setIntegrationKey] = useState("");
    const [qrHash, setQrHash] = useState("");
    const [sendData, setSendData] = useState({
        selectedCompany: null,
        selectedBusiness: null,
        selectedPricelist: null,
        selectedPricelistInstance: null
    });

    useEffect(() => {
        dataProvider.get("quickr/pos-sets")
            .then(value => {
                setCompany(value.data.list);
                const storedCompanyId = localStorage.getItem("integrationPosSet");
                if (storedCompanyId) {
                    const selectedCompany = value.data.list.find(item => item.id === parseInt(storedCompanyId));
                    setSendData(prevData => ({ ...prevData, selectedCompany }));
                    setBusiness(selectedCompany.children)
                }
            });
    }, []);

    useEffect(() => {
        if (sendData.selectedCompany) {
            localStorage.setItem("integrationPosSet", sendData.selectedCompany.id);
            dataProvider.get("quickr/company-data", { companyId: sendData.selectedCompany.id })
                .then(value => {
                    setIntegrationKey(value.data.integrationKey || "");
                    setQrHash("");
                    setSendData(prevData => ({
                        ...prevData,
                        selectedBusiness: null,
                        selectedPricelist: null,
                        selectedPricelistInstance: null
                    }));
                })
                .catch(console.log);
        }
    }, [sendData.selectedCompany]);

    useEffect(() => {
        if (sendData.selectedBusiness) {
            dataProvider.get("quickr/business-unit-data", { businessUnitId: sendData.selectedBusiness.id })
                .then(value => {
                    setQrHash(value.data.quickRId || "");
                    setInstanceId(value.data.priceListInstanceId);
                    dataProvider.get("quickr/pricelist-non-pageable", { buId: sendData.selectedBusiness.id, sort: ['name', 'ASC'] })
                        .then(pricelists => {
                            setPricelist(pricelists.data);
                            const selectedPricelist = pricelists.data.find(item => item.id === value.data.priceListTemplateId);
                            setSendData(prevData => ({ ...prevData, selectedPricelist }));
                        })
                        .catch(console.log);
                })
                .catch(console.log);
        }else {
            setPricelist([]);
            setPricelistInstance([]);
            setQrHash("");
        }

    }, [sendData.selectedBusiness]);

    useEffect(() => {
        if (sendData.selectedPricelist) {
            dataProvider.get("quickr/pricelist-instances", { priceListTemplateId: sendData.selectedPricelist.id, sort: ['id', 'DESC'] })
                .then(value => {
                    setPricelistInstance(value.data);
                    const selectedPricelistInstance = value.data.find(item => item.id === instanceId);
                    setSendData(prevData => ({ ...prevData, selectedPricelistInstance }));
                })
                .catch(console.log);
        }
    }, [sendData.selectedPricelist, instanceId]);

    const handleSubmit = () => {
        dataProvider.create("quickr", {
            data: {
                businessUnitId: sendData.selectedBusiness.id,
                qrUnit: qrHash,
                companyId: sendData.selectedCompany.id,
                integrationKey: integrationKey,
                priceListInstanceId: sendData.selectedPricelistInstance?.id
            }
        })
            .then(() => {
                notify(translate("quickR.success"), 'success');
            })
            .catch(console.log);
    };

    return (
        <Box sx={{ paddingLeft: '15px' }}>
            <Box>
                <Autocomplete
                    sx={{ width: '300px' }}
                    options={company || []}
                    renderInput={(params) => <TextField {...params} label={translate("quickR.company")} variant={"standard"} />}
                    getOptionLabel={(option) => option.name || ""}
                    value={sendData.selectedCompany}
                    onChange={(event, newValue) => {
                        setSendData(prevData => ({ ...prevData, selectedCompany: newValue }));
                        setBusiness(newValue?.children || []);
                    }}
                />
                <TextField
                    sx={{ width: '300px' }}
                    variant={"standard"}
                    label={translate("quickR.integrationKey")}
                    value={integrationKey}
                    onChange={(event) => setIntegrationKey(event.target.value)}
                />
            </Box>

            <Box>
                <Autocomplete
                    sx={{ width: '300px' }}
                    options={business || []}
                    renderInput={(params) => <TextField {...params} label={translate("quickR.business")} variant={"standard"} />}
                    getOptionLabel={(option) => option.name || ""}
                    value={sendData.selectedBusiness}
                    onChange={(event, newValue) => {
                        setSendData(prevData => ({ ...prevData, selectedBusiness: newValue }));
                    }}
                    disabled={!sendData.selectedCompany || integrationKey.length < 1}
                />
                <TextField
                    sx={{ width: '300px' }}
                    variant={"standard"}
                    label={translate("quickR.hash")}
                    value={qrHash}
                    onChange={(event) => setQrHash(event.target.value)}
                    disabled={!sendData.selectedBusiness}
                />
                <Autocomplete
                    sx={{ width: '300px' }}
                    options={pricelist || []}
                    renderInput={(params) => <TextField {...params} label={translate("quickR.pricelist")} variant={"standard"} />}
                    getOptionLabel={(option) => option.name || ""}
                    value={sendData.selectedPricelist}
                    onChange={(event, newValue) => {
                        setSendData(prevData => ({ ...prevData, selectedPricelist: newValue }));
                    }}
                    disabled={!sendData.selectedCompany || integrationKey.length < 1}
                />
                <Autocomplete
                    sx={{ width: '300px' }}
                    options={pricelistInstance || []}
                    renderInput={(params) => <TextField {...params} label={translate("quickR.pricelistInstance")} variant={"standard"} />}
                    getOptionLabel={(option) => option.name || ""}
                    value={sendData.selectedPricelistInstance}
                    onChange={(event, newValue) => {
                        setSendData(prevData => ({ ...prevData, selectedPricelistInstance: newValue }));
                    }}
                    disabled={!sendData.selectedCompany || integrationKey.length < 1}
                />
            </Box>
            <hr style={{ width: '100%', marginTop: '30px' }} />
            <Button
                disabled={!sendData.selectedCompany || !sendData.selectedBusiness || integrationKey.length < 1 || !qrHash || !sendData.selectedPricelist || !sendData.selectedPricelistInstance}
                onClick={handleSubmit}
                variant={"contained"}
                color={"secondary"}
                sx={{ fontSize: '15px' }}
            >
                <SaveIcon sx={{ marginRight: '5px' }} /> {translate("quickR.save")}
            </Button>
        </Box>
    );
};

export default QuickRIntegration;