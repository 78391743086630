import dataProvider from "../dataProvider";
import Privileges from "../authentication/Privileges"
import { AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import userPool from "./userPool";

// Amplify.configure({
//         Auth: {
//             // REQUIRED - Amazon Cognito Region
//             region: process.env.REACT_APP_AWS_REGION,
//
//             // OPTIONAL - Amazon Cognito User Pool ID
//             userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
//
//             // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//             userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,
//
//             // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//             mandatorySignIn: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN,
//     },
// });
function getMe() {
    return new Promise((resolve, reject) => {
        dataProvider.get("auth/me").then(
        value => {
            localStorage.setItem('permission', JSON.stringify( value.data.privileges.map(it => it.id)))
            localStorage.setItem('businessUnits', JSON.stringify(value.data.businessUnits))
            localStorage.setItem('companies', JSON.stringify(value.data.companies))
            resolve(value.data.privileges.map(it => it.id));
        }
    ).catch(reason=>{
            reject(reason);
        })
    }
    )
}

function getInv(){
    if(document.hasFocus()){
        const inviteToken = localStorage.getItem("inviteToken")
        localStorage.removeItem("inviteToken")
        return new Promise((resolve, reject) => {
            if (inviteToken !== null) {
                dataProvider.create("invitation/privilege", {data: {"uuid": inviteToken}}).then(r => {
                    localStorage.setItem("inviteNotify", "true")
                    resolve(r)
                }).catch(reason => {
                    localStorage.setItem("inviteNotify", "false")
                    reject(reason)
                })
            }
            resolve("Not inv: ok!")
        })
    }
}

function getPrivilegesFromBE(){
    return new Promise((resolve, reject) => {
            dataProvider.get("auth/privileges").then(
                value => {
                    Privileges.Privilege = value.data
                    resolve("ok");
                }
            ).catch(reason=>{
                reject(reason);
            })
        }
    )
}

const authProvider = {
    login: (params) => {
        return new Promise((resolve, reject) => {

            const authDetails = new AuthenticationDetails({
                Username: params.email,
                Password: params.password,
            });

            const user = new CognitoUser({
                Username: params.email,
                Pool: userPool,
            });

            user.authenticateUser(authDetails,{
                onSuccess:(result)=>{
                    const accessToken = result.getAccessToken().getJwtToken();
                    localStorage.setItem('username', params.email);
                    localStorage.setItem('token', accessToken);
                    resolve(result);
                },
                onFailure:(err)=>{
                    console.log("login failed",err);
                    reject(err);
                }
            });

        });

    },
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        localStorage.removeItem('businessUnits');
        localStorage.removeItem('companies');
        localStorage.clear();
        window.location.href = process.env.REACT_APP_NEW_URL;
    },

    checkAuth: () => {
        return new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (!user) {
                reject();
            }else {
                user.getSession((err, session) => {
                    if (err) {
                        console.log(err)
                        reject(err);
                    } else {
                        localStorage.setItem('token', session.getAccessToken().getJwtToken());
                        resolve();
                    }
                });
            }
        })
    },


    checkError:  (error) => {
        const status = error?.status;
        if (status === 401 /*|| status === 403*/) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('username'),
        }),
    getPermissions: async () => {
        console.log("getpermission", new Date())
        await getInv()
        await getPrivilegesFromBE()
        await getMe()
        return Promise.resolve(JSON.parse(localStorage.getItem('permission')))
    },

};

export default authProvider;
