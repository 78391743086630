import React from 'react';
import {Edit, SimpleForm} from "react-admin";
import ExtrasDetails from "./ExtrasDetails";
import {PinkToolbar} from "../../components/PinkToolbar";

const ExtrasEdit = (props) => {
    return (
        <Edit redirect={"list"} {...props}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={true} alwaysEnableSave={true}/>}>
                <ExtrasDetails/>
            </SimpleForm>
        </Edit>
    )
}

export default ExtrasEdit;