import React, { useEffect, useState } from 'react';
import {
    TextField,
    InfiniteList,
    SearchInput,
    useTranslate,
    useUnselectAll,
    BooleanField,
    ArrayField,
    SingleFieldList,
    ChipField,
    AutocompleteArrayInput,
    useDataProvider,
    FilterForm,
    useListContext,
    FunctionField,
    BooleanInput,
} from 'react-admin';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ProductSelectBulkAction } from "./ProductSelectBulkAction";
import { Datagrid as ReactAdminDataGrid } from "react-admin";
import EditPriceDataGrid from "./EditPriceDataGrid";
import AddIcon from '@mui/icons-material/Add';
import removeAccents from "./removeAccents";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Tooltip from '@mui/material/Tooltip';
import RecyclingIcon from '@mui/icons-material/Recycling';

const ListItems = ({ products }) => {
    const translate = useTranslate();
    if (products && products.length > 0) {
        return (
            <EditPriceDataGrid rows={products} />
        );
    } else {
        return <div> {translate("priceListTemplate.noProductInPriceList")} </div>;
    }
};

export const ProductToPriceList = (formData) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const unselectAll = useUnselectAll('priceList/products/byUnitPageable');
    const [tag, setTag] = useState({ loading: false, loaded: false, data: [], error: undefined });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!tag.loading && !tag.loaded) {
            setTag({ ...tag, loading: true });
            dataProvider.get(`priceList/product-tags`, { posSetId: localStorage.getItem("priceListPosSetId") })
                .then(value => {
                    setTag({ loading: false, loaded: true, data: value.data.sort((a, b) => removeAccents(a.name) > removeAccents(b.name) ? 1 : -1), error: undefined });
                })
                .catch(reason => {
                    setTag({ loading: false, loaded: true, data: [], error: reason });
                });
        }
    }, [tag.loading, tag.loaded]);

    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        unselectAll();
    };

    const TooMuchProduct = () => {
        const { total } = useListContext();
        return (
            total > 2000 ?
                <span style={{ position: "absolute", color: "red", marginTop: "-65px" }}> {translate("priceListTemplate.tooMuch")} </span>
                :
                ""
        );
    };

    const NoProducts = () => <Typography>{translate("products.empty")}</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : [];
    const [filteredIds, setFilteredIds] = useState([]);

    useEffect(() => {
        if (formData.formData.products) {
            setFilteredIds(formData.formData.products.map(product => product.productUnitId));
        }
    }, [products]);

    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const postFilters = [
        <SearchInput sx={{ '& input': { height: '31px' }, width: "300px" }} source="nameSearch" alwaysOn={true} resettable={true} />,
        <AutocompleteArrayInput label={'products.filterTags'} source="tags" choices={tag.data} alwaysOn sx={{ minWidth: "300px" }}
                                matchSuggestion={matchSuggestion}
        />
    ];

    return (
        <Box sx={{width: "100%"}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: "40%", alignItems: 'center', marginBottom: "15px" }}>
                <Button style={{ color: "white" }} variant={"contained"} color={"primary"} onClick={openAddDialog}>
                    <AddIcon />   {translate('priceListTemplate.addProductsToPriceList')}
                </Button>
                <BooleanInput label={translate("priceListTemplate.quickRCompatible")} source="quickRPriceList" sx={{height: '40px'}}/>
            </Box>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"xl"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    {translate('priceListTemplate.addProductsToPriceList')}
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: "700px" }}>
                        <InfiniteList empty={<NoProducts />} exporter={false} resource={"priceList/products/byUnitPageable"}
                                      filter={{ excludedIds: [filteredIds], buId: formData.formData.posSetId || localStorage.getItem("priceListPosSetId"), size: 2000 }}
                                      title={" "} pagination={false} perPage={2000}
                        >
                            <TooMuchProduct />
                            <FilterForm filters={postFilters} style={{ marginTop: "-52px", marginBottom: "50px" }} />
                            <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectBulkAction current={products} setOpen={setOpen} />}>
                                <TextField source="product.name" label={"products.name"} />
                                <FunctionField render={record => `${record.quantity} ${record.unit}`} label={"products.qtyUnit"} sortable={false} />
                                <TextField source="product.vatName" sortable={false} label={"products.vatGroup"} />
                                <FunctionField render={record => record.hasRecipe ? <Tooltip title={translate("products.recipeTooltip")}> <ReceiptLongIcon /> </Tooltip> : "X"} label={"products.recipe"} />
                                <FunctionField render={record => record.depositFee.hasDepositFee === 'MULTI_WAY' || record.depositFee === 'ONE_WAY' ? <Tooltip title={translate("products.refundBottle")}> <RecyclingIcon /> </Tooltip> : ""} label={"products.refundBottle"} />
                                <BooleanField source="product.isTakeawayAllowed" sortable={false} label={"products.isTakeawayAllowed"} />
                                <TextField source="product.takeawayVatName" sortable={false} label={"products.takeawayVatGroup"} />
                                <TextField source="product.mainCategory" label={"products.mainCategory"} />
                                <TextField source="product.subCategory" label={"products.subCategory"} />
                                <TextField source="price" sortable={false} label={"products.price"} />
                                <BooleanField source="bulk" sortable={false} label={"products.bulkItem"} />
                                <ArrayField source={"product.tags"} label={'products.tags'} sortable={false}>
                                    <SingleFieldList linkType={false}>
                                        <ChipField source={"name"} />
                                    </SingleFieldList>
                                </ArrayField>
                            </ReactAdminDataGrid>
                        </InfiniteList>
                    </div>
                </DialogContent>
            </Dialog>
            <ListItems products={products} />
        </Box>
    );
};