import { useListContext } from "react-admin";
import React, { useEffect } from "react";

const BulkActionButtonsToExtra = React.memo(({ selectedProducts, setSelectedIds }) => {
    const { selectedIds, onSelect } = useListContext();

    useEffect(() => {
        onSelect(selectedProducts);
        setSelectedIds(selectedProducts);
    },[selectedProducts]);

    useEffect(() => {
        setSelectedIds(selectedIds);
    },[selectedIds]);

    return null;
});

export default BulkActionButtonsToExtra;
