import React from 'react';
import {Create, SimpleForm} from "react-admin";
import ExtrasDetails from "./ExtrasDetails";
import {PinkToolbar} from "../../components/PinkToolbar";

const ExtrasCreate = (props) => {

    const transform = (data) => {
        data.posSetId = localStorage.getItem("extraPosSetId")
        return data
    }

    return (
        <Create {...props} redirect={"list"} title={'products.create'} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false}/>}>
                <ExtrasDetails/>
            </SimpleForm>
        </Create>
    )
}

export default ExtrasCreate;