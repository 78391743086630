import * as React from 'react';
import {useEffect, useState} from 'react';
import {LocalesMenuButton, useAuthState, useLogin, useNotify, useRedirect, useTranslate} from 'react-admin';
import {
    Box,
    Button,
    Checkbox, CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { CognitoUserAttribute, CognitoUser} from "amazon-cognito-identity-js";
import userPool from "./userPool";
import CryptoJS from "crypto-js";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const PposLoginPage = ({ theme }) => {
    const { isLoading, authenticated } = useAuthState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forEmail, setForEmail] = useState('');
    const [forCode, setForCode] = useState('');
    const [forNewPassword, setForNewPassword] = useState('');
    const [forNewPasswordAgain, setForNewPasswordAgain] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regPasswordAgain, setRegPasswordAgain] = useState('');
    const [regGivenName, setRegGivenName] = useState('');
    const [regFamilyName, setRegFamilyName] = useState('');
    const [regPhone, setRegPhone] = useState('');
    const [regPin, setRegPin] = useState('');
    const [helloPayPosUser, setHelloPayPosUser] = useState('');
    const [verEmail, setVerEmail] = useState('');
    const [verCode, setVerCode] = useState('');
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [disableRegistration, setDisableRegistration] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate();
    const [value, setValue] = React.useState(0);
    const [showPassword, setShowPassword] = useState({signIn: false, forgotPassword: false, register: false})
    const [showPasswordAgain, setShowPasswordAgain] = useState({signIn: false, forgotPasswordAgain: false, register: false})
    const passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\.+,!@#$%^&*:;?_|~={}()<>`"/\'\[\\\\\\\]-]).{8,99}$'
    const [searchParams] = useSearchParams();
    const redirect = useRedirect();
    const url = window.location.href.split('#')[0]
    const [spinner, setSpinner] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        if (window.location.href.includes("data=")) {
            setSpinner(true);
        }else {
            setSpinner(false);
        }

    },[])

    useEffect(() => {
        if (window.location.href.includes("data=")) {
        const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY
        try {
            const url = new URL(window.location.href);
            const hash = url.hash;
            const queryString = hash.split('?')[1];
            const searchParams = new URLSearchParams(queryString);
            const encryptedParam = searchParams.get("data");

            if (!encryptedParam) {
                console.error("Nincs titkosított adat az URL-ben.");
                return null;
            }

            const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParam), SECRET_KEY);
            const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
            const decryptedData = JSON.parse(jsonString);
            login({email: decryptedData.userName, password: decryptedData.password})
                .then(() => {
                    navigate(decryptedData.redirect)
                })
                .catch(err => {
                    console.log(err);
                    notify('signUp.notAuthorizedException', { type: 'error' });
                });
        } catch (error) {
            console.error("Hiba a visszafejtés során:", error);
            return null;
        }
    }
    },[])

    useEffect(() => {
        if(searchParams.get("inviteToken")) {
            localStorage.setItem("inviteToken", searchParams.get("inviteToken"))
            if(!isLoading && authenticated){
                redirect("/company")
            }
        }else {
            localStorage.removeItem("inviteToken")
        }
    }, [isLoading])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handlePinChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRegPin(value);
    };

    const handleHelloPayPosUserChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setHelloPayPosUser(value);
    };

    const handleLogInSubmit = e => {
        e.preventDefault();
        // will call authProvider.login({ email, password })
        login({email, password}).catch(err => {
            console.log(err);
            notify('signUp.notAuthorizedException', { type: 'error' });
        });
    };

    const handleSubmitForgot = (e) => {
        e.preventDefault();
            const cognitoUser = new CognitoUser({
                Username: forEmail,
                Pool: userPool,
            })
           cognitoUser.forgotPassword({
                onSuccess: (data) => {
                    console.log(data);
                    notify('signUp.verificationCodeSent', { type: 'success' })
                },
                onFailure: (err) => {
                   console.log(err);
                   if(err.message.includes('Invalid verification')){
                       notify('signUp.invalidVerificationCode', { type: 'error' })
                   }

               }
           })
    };

const handleResetPasswordNextSteps = async (output)=> {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            console.log(
                `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
            );
            break;
        case 'DONE':
            notify('signUp.verificationCodeSent', { type: 'success' })
            break;
    }
}

    const handleSubmitForgotSubmit = e => {
        e.preventDefault();
        if(forNewPassword === forNewPasswordAgain){
            const cognitoUser = new CognitoUser({
                Username: forEmail,
                Pool: userPool,
            })

            cognitoUser.confirmPassword(forCode, forNewPassword, {
                onSuccess: (data) => {
                    console.log(data);
                    notify('signUp.successfulPasswordChange', { type: 'success' })
                    setValue(0);
                    setForEmail('')
                    setForCode('')
                    setForNewPassword('')
                    setForNewPasswordAgain('')
                },
                onFailure: (err) => {
                    console.log(err);
                    if(err.message.includes('Invalid verification')){
                        notify('signUp.invalidVerificationCode', { type: 'error' })
                    }
                    if(err.message.includes('Password does not conform to policy')){
                        notify('signUp.passwordPolicy', { type: 'error' })
                    }
                    if(err.code==='ExpiredCodeException'){
                        notify('signUp.expiredVerificationCode', { type: 'error' })
                    }
                    if(err.code==='LimitExceededException'){
                        notify('signUp.limitExceededException', { type: 'error' })
                    }
                }
            })
            }

        else  {
            notify('signUp.passwordDifferentException', { type: 'error' })
        }
    };
    const handleSubmitRegistration = e => {
        e.preventDefault();
        setDisableRegistration(true);
        let validation = true;
        if(regGivenName == null || regGivenName === ""){
            notify('signUp.givenNameEmpty', { type: 'error' })
            validation = false;
        }
        if(regFamilyName == null || regFamilyName === ""){
            notify('signUp.familyNameEmpty', { type: 'error' })
            validation = false;
        }
        if(regPassword !== regPasswordAgain){
            notify('signUp.passwordDifferentException', { type: 'error' })
            validation = false;
        }
        if(termsAndConditions === false || privacyPolicy === false){
            notify('signUp.termsAndConditionsUnaccepted', { type: 'error' })
            validation = false;
        }
        if(validation){
            const attributes = [
                new CognitoUserAttribute({Name: "given_name", Value: regGivenName}),
                new CognitoUserAttribute({Name: "family_name", Value: regFamilyName}),
                new CognitoUserAttribute({Name: "email", Value: regEmail}),
                new CognitoUserAttribute({Name: "phone_number", Value: regPhone}),
                new CognitoUserAttribute({Name: "custom:pin", Value: regPin}),
                new CognitoUserAttribute({Name: "custom:hellopay_pos_user_id", Value: helloPayPosUser}),
                new CognitoUserAttribute({Name: "custom:termsAndConditions", Value: termsAndConditions.toString()}),
                new CognitoUserAttribute({Name: "custom:privacyPolicy", Value: privacyPolicy.toString()}),
            ];
            userPool.signUp(regEmail, regPassword, attributes, null, (err, data) => {
                if (err) {
                    if(err.message.includes('Password cannot be empty')){
                        notify('signUp.passwordEmpty', { type: 'error' })
                    }
                    if(err.message.includes('Password did not conform with policy')){
                        notify('signUp.passwordPolicy', { type: 'error' })
                    }
                    if(err.message.includes('An account with the given email already exists.')){
                        notify('signUp.accountExist', { type: 'error' })
                    }
                    console.log(err)
                }else {
                    notify('signUp.successfulRegistration', { type: 'success' })
                    setValue(3);
                    setRegEmail('')
                    setRegPassword('')
                    setRegPasswordAgain('')
                    setRegFamilyName('')
                    setRegGivenName('')
                    setRegPhone('')
                    setRegPin('')
                    setHelloPayPosUser('')
                    setTermsAndConditions(false)
                    setPrivacyPolicy(false)
                }

                })
            }else {
            setDisableRegistration(false);
        }
          }

    const handleSubmitVerification = e => {
        e.preventDefault();
        const userData = {
            Username: verEmail,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(verCode, true, (err, result) => {
            if (err) {
                console.log(err);
                if(err.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
                if(err.code==='ExpiredCodeException'){
                    notify('signUp.expiredVerificationCode', { type: 'error' })
                }
                if(err.code==='LimitExceededException'){
                    notify('signUp.limitExceededException', { type: 'error' })
                }
            }else {
                console.log(result);
                notify('signUp.successfulVerification', { type: 'success' })
                setValue(0);
                setVerEmail('')
                setVerCode('')
            }
        })

    };

    const handleClickShowSignInPassword = () => {
        setShowPassword({...showPassword, signIn:!showPassword.signIn})
    };
    const handleClickShowForgetPassword = () => {
        setShowPassword({...showPassword, forgotPassword:!showPassword.forgotPassword})
    };
    const handleClickShowForgetPasswordAgain = () => {
        setShowPasswordAgain({...showPasswordAgain, forgotPasswordAgain:!showPasswordAgain.forgotPasswordAgain})
    };

    const handleClickShowRegisterPassword = () => {
        setShowPassword({...showPassword, register:!showPassword.register})
    };

    const handleClickShowRegisterPasswordAgain = () => {
        setShowPasswordAgain({...showPasswordAgain, register:!showPasswordAgain.register})
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownPasswordAgain = (event) => {
        event.preventDefault();
    };

    return (
        spinner ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                       <CircularProgress/>
                        <Typography variant="body2" component="p" gutterBottom>
                            {translate('signUp.verification')}
                        </Typography>
                    </Box>
                </div>
              :
                <div align="center">
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label={translate('signUp.login')} {...a11yProps(0)} />
                                <Tab label={translate('signUp.forgotPassword')} {...a11yProps(1)} />
                                <Tab label={translate('signUp.registration')} {...a11yProps(2)} />
                                <Tab label={translate('signUp.verification')} {...a11yProps(3)} />
                            </Tabs>
                            <Box style={{position: "absolute", right: "15px", top: "10px"}}>
                                <LocalesMenuButton
                                    languages={[
                                        {locale: 'hu', name: 'Magyar'},
                                        {locale: 'en', name: 'English'},
                                    ]}
                                />
                            </Box>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <form onSubmit={handleLogInSubmit}>
                                <h2>{translate('signUp.login')}</h2>
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12}>
                                        <TextField required id="email" label={translate('signUp.email')}
                                                   variant="filled" type="email" value={email}
                                                   onChange={e => setEmail(e.target.value)} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="password" label={translate('signUp.password')}
                                                   variant="filled" value={password}
                                                   onChange={e => setPassword(e.target.value)} style={{width: 400}}
                                                   type={'password'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit" color="primary"
                                                style={{color: "white"}}>{translate('signUp.signIn')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <form onSubmit={handleSubmitForgot}>
                                <h2>{translate('signUp.forgotPassword')}</h2>
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12}>
                                        <TextField required id="forEmail" label={translate('signUp.email')}
                                                   variant="filled" type="email" value={forEmail}
                                                   onChange={e => setForEmail(e.target.value)} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit" color="primary"
                                                style={{color: "white"}}>{translate('signUp.verificationCodeRequest')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                            <form onSubmit={handleSubmitForgotSubmit}>
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12}>
                                        <TextField required id="forCode" autoComplete={"off"}
                                                   label={translate('signUp.verificationCode')} variant="filled"
                                                   type="text" value={forCode}
                                                   onChange={e => setForCode(e.target.value)} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="forNewPassword" autoComplete={"new-password"}
                                                   label={translate('signUp.newPassword')}
                                                   variant="filled"
                                                   type={showPassword.forgotPassword ? 'text' : 'password'}
                                                   value={forNewPassword}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={handleClickShowForgetPassword}
                                                                   onMouseDown={handleMouseDownPassword}
                                                                   edge="end"
                                                               >
                                                                   {showPassword.forgotPassword ? <VisibilityOff/> :
                                                                       <Visibility/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                                   inputProps={{
                                                       inputMode: 'text',
                                                       pattern: passwordPattern
                                                   }}
                                                   helperText={translate('signUp.passwordHint')}
                                                   onChange={e => setForNewPassword(e.target.value)}
                                                   style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="forNewPasswordAgain"
                                                   label={translate('signUp.newPasswordAgain')}
                                                   variant="filled"
                                                   type={showPasswordAgain.forgotPasswordAgain ? 'text' : 'password'}
                                                   value={forNewPasswordAgain}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={handleClickShowForgetPasswordAgain}
                                                                   onMouseDown={handleMouseDownPasswordAgain}
                                                                   edge="end"
                                                               >
                                                                   {showPasswordAgain.forgotPasswordAgain ?
                                                                       <VisibilityOff/> :
                                                                       <Visibility/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                   }
                                                   }
                                                   inputProps={{
                                                       inputMode: 'text',
                                                       pattern: passwordPattern
                                                   }}
                                                   onChange={e => setForNewPasswordAgain(e.target.value)}
                                                   style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit" color="primary"
                                                style={{color: "white"}}>{translate('signUp.save')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <form onSubmit={handleSubmitRegistration}>
                                <h2>{translate('signUp.registration')}</h2>
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12}>
                                        <TextField required id="regEmail" autoComplete={"off"}
                                                   label={translate('signUp.email')} variant="filled" type="email"
                                                   value={regEmail}
                                                   defaultValue={regEmail}
                                                   onChange={e => setRegEmail(e.target.value)} style={{width: 400}}
                                                   inputProps={{maxLength: 255}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regPassword" autoComplete={"new-password"}
                                                   label={translate('signUp.password')}
                                                   variant="filled" type={showPassword.register ? 'text' : 'password'}
                                                   value={regPassword}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={handleClickShowRegisterPassword}
                                                                   onMouseDown={handleMouseDownPassword}
                                                                   edge="end"
                                                               >
                                                                   {showPassword.register ? <VisibilityOff/> :
                                                                       <Visibility/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                   }
                                                   }
                                                   inputProps={{
                                                       inputMode: 'text',
                                                       pattern: passwordPattern,
                                                       maxLength: 99
                                                   }}
                                                   helperText={translate('signUp.passwordHint')}
                                                   onChange={e => setRegPassword(e.target.value)} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regPasswordAgain"
                                                   label={translate('signUp.newPasswordAgain')}
                                                   variant="filled"
                                                   type={showPasswordAgain.register ? 'text' : 'password'}
                                                   value={regPasswordAgain}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={handleClickShowRegisterPasswordAgain}
                                                                   onMouseDown={handleMouseDownPasswordAgain}
                                                                   edge="end"
                                                               >
                                                                   {showPasswordAgain.register ? <VisibilityOff/> :
                                                                       <Visibility/>}
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                   }
                                                   }
                                                   inputProps={{
                                                       inputMode: 'text',
                                                       pattern: passwordPattern,
                                                       maxLength: 99
                                                   }}
                                                   onChange={e => setRegPasswordAgain(e.target.value)}
                                                   style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regGivenName" label={translate('signUp.givenName')}
                                                   variant="filled" type="text" value={regGivenName}
                                                   onChange={e => setRegGivenName(e.target.value)} style={{width: 400}}
                                                   inputProps={{maxLength: 255}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regFamilyName" label={translate('signUp.familyName')}
                                                   variant="filled" type="text" value={regFamilyName}
                                                   onChange={e => setRegFamilyName(e.target.value)} style={{width: 400}}
                                                   inputProps={{maxLength: 255}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regPhone" label={translate('signUp.phone')}
                                                   variant="filled" type="text" value={regPhone}
                                                   inputProps={{inputMode: 'text', pattern: '\\+\\d{5,12}'}}
                                                   helperText={translate('signUp.phoneHint')}
                                                   onChange={e => setRegPhone(e.target.value)} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="regPin" label={translate('signUp.pin')} variant="filled"
                                                   type="text" value={regPin} inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9][0-9][0-9][0-9]',
                                            maxLength: 4,
                                            minLength: 4
                                        }} helperText={translate('signUp.pinHint')} onChange={handlePinChange}
                                                   style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="helloPayPosUser" label={translate('signUp.helloPayPosUser')}
                                                   variant="filled" type="text" value={helloPayPosUser} inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '\\d{4,20}',
                                            maxLength: 20,
                                            minLength: 4
                                        }} helperText={translate('signUp.helloPayPosUserHint')}
                                                   onChange={handleHelloPayPosUserChange} style={{width: 400}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{width: '420px', textAlign: 'left'}}>
                                            <Checkbox color="primary"
                                                      onChange={() => setTermsAndConditions(!termsAndConditions)}/>
                                            <Typography variant="body2" component={"span"} style={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontSize: '0.75rem'
                                            }}>                                            {translate('signUp.readAndAccepted')}{' '}
                                                <Link to={url.concat('aszf.pdf')} target="_blank">
                                                    {translate('signUp.termsAndConditions')}
                                                </Link>
                                            </Typography>
                                        </div>
                                        <div style={{width: '420px', textAlign: 'left'}}>
                                            <Checkbox color="primary"
                                                      onChange={() => setPrivacyPolicy(!privacyPolicy)}/>
                                            <Typography variant="body2" component={"span"} style={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontSize: '0.75rem',
                                                width: '355px'
                                            }}>                                            {translate('signUp.readAndAccepted')}{' '}
                                                <Link to={url.concat('adatvedelem.pdf')} target="_blank">
                                                    {translate('signUp.privacyPolicy')}
                                                </Link>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={disableRegistration} variant="contained" type="submit"
                                                color="primary"
                                                style={{color: "white"}}>{translate('signUp.signUp')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <form onSubmit={handleSubmitVerification}>
                                <h2>{translate('signUp.verification')}</h2>
                                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                    <Grid item xs={12}>
                                        <TextField required id="verEmail" label={translate('signUp.email')}
                                                   variant="filled" type="email" value={verEmail}
                                                   onChange={e => setVerEmail(e.target.value)} style={{width: 400}}
                                                   inputProps={{maxLength: 255}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required id="verCode" label={translate('signUp.verificationCode')}
                                                   variant="filled" type="text" value={verCode}
                                                   onChange={e => setVerCode(e.target.value)} style={{width: 400}}
                                                   inputProps={{maxLength: 99}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit" color="primary"
                                                style={{color: "white"}}>{translate('signUp.verification')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                    </Box>
                </div>
    );
};

export default PposLoginPage;