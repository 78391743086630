import {Edit, FormDataConsumer, SimpleForm, useNotify, useRedirect} from "react-admin";
import PrinterEditDetails from "./PrinterEditDetails";
import {PinkToolbar} from "../../components/PinkToolbar";
import React, {useState} from "react";
import {useDataProvider} from "react-admin";

const PrinterEdit = () => {

    const [selectedIds, setSelectedIds] = useState([])
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect()
console.log(selectedIds)
    const printerUpdate = (values) => {
        dataProvider.update(`v1/order/printer/${values.id}`, {name: values.name, serialNumber: values.serialNumber})
            .then(response => {
                notify('Nyomtató módosítva', {type: "success"});
            })
            .catch(error => {
                console.log(error)
            })
        dataProvider.create('v1/order/printer/products', {
            data: {
                orderPrinterId: values.id,
                productIds: selectedIds,
                buId: localStorage.getItem("v1/order/printerPosSetId")
            }
        })
            .then(response => {
                notify('Termékek hozzáadva a nyomtatóhoz', {type: "success"});
            })
            .catch(error => {
                console.log(error)
                notify('Hiba történt a termékek hozzáadása közben', {type: "error"});
            })
        redirect('list', '/v1/order/printer')
    }

    return (
        <Edit redirect={'list'}>
            <SimpleForm onSubmit={printerUpdate} toolbar={<PinkToolbar alwaysEnableSave={true}/>}>
                <FormDataConsumer>
                    {({formData}) => {
                        return (
                            <PrinterEditDetails formData={formData} setSelectedIds={setSelectedIds}/>
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}

export default PrinterEdit;