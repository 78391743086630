import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box, IconButton, TextField } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const ProductCard = ({ product, onAddToCart }) => {
    const [quantity, setQuantity] = useState(0);
    const [serialNumber, setSerialNumber] = useState('');

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    };

    const handleAddToCart = () => {
        const productToAdd = {
            name: product.name,
            quantity: product.id === 8 ? 1 : quantity,
            serialNumber: product.id === 8 ? serialNumber : null
        };
        onAddToCart(productToAdd);
    };

    return (
        <Card sx={{ width: 345, height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.name}
                sx={{ objectFit: 'contain' }}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {product.name}
                </Typography>
                {product.id === 8 ? (
                    <TextField
                        variant={"standard"}
                        label="Sorszám"
                        value={serialNumber}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setSerialNumber(value);
                            }
                        }}
                    />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                        <IconButton onClick={handleDecrease}>
                            <RemoveIcon />
                        </IconButton>
                        <Typography variant="h6" component="span" sx={{ mx: 2 }}>
                            {quantity}
                        </Typography>
                        <IconButton onClick={handleIncrease}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button color="primary" disabled={product.id !== 8 && quantity === 0} startIcon={<AddShoppingCartIcon />} onClick={handleAddToCart}>
                        Kosárba
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProductCard;